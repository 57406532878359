const Navigation = () => {
  return (
    <header className="p-4 shadow-md">
      <nav className="container mx-auto flex justify-between h-8 sticky top-0">
        <img
          src="https://assets-global.website-files.com/613d02e4e28f9c1b2da6bf15/634092baa41deb3f33f60979_logo.svg"
          className="h-5 m-0 mt-1.5"
        />
      </nav>
    </header>
  );
};

export default Navigation;