import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { meetPersona, findById } from './db.js';
import useImage from './use-image';

import smallAnimationGif from '../assets/images/small-animation.gif';
import iconGreenCheck from '../assets/images/icon-green-check.webp';

const howItWorks = [
  'Clearword joins my calls.',
  'It takes notes and finds the work to do during the call.',
  'Creates tickets, updates my CRM and composes follow-ups in real-time as we talk.',
  `I leave the call and I'm done, my tasks are organized and I can focus on deep work again.`,
];

const Meet = () => {
  const [post, setPost] = useState({});
  const { id } = useParams();
  const howToRef = useRef(null);
  const [visibleItems, setVisibleItems] = useState([]);

  const learnMoreUtm = `utm_campaign=meet&utm_medium=display&utm_source=meet&utm_content=meet.${id}`;
  const tryForFreeUrl = `https://home.clearword.com?utm_campaign=meet&utm_medium=display&utm_source=meet&utm_content=signup.${id}`;

  const scrollToHowTo = () => howToRef.current.scrollIntoView({behavior: 'smooth'});

  useEffect(() => {
    setPost(findById(id));
  }, [id]);

  useEffect(() => {
    const maxItems = howItWorks?.length || 0;
    let currentVisible = 0;

    const intervalId = setInterval(() => {
      if (currentVisible < maxItems) {
        setVisibleItems(v => [...v, currentVisible]);
        currentVisible += 1;
      } else {
        clearInterval(intervalId);
      }
    }, 500); // Adjust the time as needed

    return () => clearInterval(intervalId);
  }, [howItWorks]);

  const { loading, error, image } = useImage(`${id}.webp`);
  if (!Object.keys(post).length) return <div />;
  if (!post.id) {
    window.location.href = 'https://clearword.com/';
    return;
  }

  return (
    <>
      {/* Meet Max: This is the top section with the intro. */}
      <div className="hero-section w-full h-[100vh] pt-12 md:pt-none justify-center container md:mx-auto px-4">
        <div className="md:flex md:flex-row md:items-center justify-center md:my-auto">
          <div className="w-5/6 md:w-2/5">
            <img
              className="w-12 h-12 md:w-64 md:h-64 rounded-full m-5 md:mx-auto"
              src={image}
              alt={`Headshot of ${post.id}`}
            />
          </div>
          <div className="chat-bubble md:chat-bubble mt-10 md:mt-none md:w-3/5 drop-shadow-lg">
            <p className="text-sm md:text-3xl mb-5 p-4">
              {post.intro}
            </p>
            <p className="text-sm md:text-3xl p-4 leading-loose md:leading-relaxed">
              {post.text}
            </p>
            <p className="my-5">
             <a
               className="bg-[#149285] border-[#149285] border rounded-[4px] px-4 py-3 ml-3 text-white drop-shadow-md hover:bg-[#2b9c91]"
               href={`${post.learn}?${learnMoreUtm}`}
               target="_blank"
             >
               Learn more →
             </a>
              <a
               className="bg-[#f2efea] border-[#149285] border rounded-[4px] px-4 py-3 ml-3 text-[#149285] drop-shadow-md hover:bg-[#2b9c91] hover:text-white"
               href={tryForFreeUrl}
               target="_blank"
             >
                Try for free →
             </a>
            </p>
            <p className="text-xs text-slate-500 italic px-4">
              Free, easy setup, no credit card required.
            </p>
          </div>
        </div>
        <div className="md:flex mt-auto justify-center pb-[75px] md:pb-8 scroll-indicator w-full text-center text-xs cursor-pointer" onClick={scrollToHowTo}>
          <div className="transform -rotate-45 w-2 h-2 border-l-2 border-b-2 border-black inline-block mr-2" />
          Let me show you, scroll down.
          <div className="transform -rotate-45 w-2 h-2 border-l-2 border-b-2 border-black inline-block ml-2" />
        </div>
      </div>

      {/* Here's how it works section  */}
      <div className="container mx-auto px-4 pt-6" ref={howToRef}>
        <div className="md:flex md:flex-cols h-[100vh]">
          <div className="mb-6 w-[90%] md:w-1/2">
            <h2 className="text-3xl font-bold mb-6">Here's how it works</h2>
            <ol className="list-decimal list-inside">
              {howItWorks?.map((item, index) => (
                <li
                  key={index}
                  className={`mb-4 flex items-center ${visibleItems.includes(index+1) ? 'fade-in' : 'invisible'}`}
                >
                  <img
                  className="w-10 h-10 rounded-full mr-4"
                  src={image}
                  alt={`Headshot of ${post.id}`}
                />
                <p className="chat-bubble-small drop-shadow-md">
                  <strong>{index+1}.</strong> {item}
                </p>
                </li>
              ))}
            </ol>
          </div>
          <div className="mb-6 w-full md:w-1/2 items-right p-4 pl-2 ">
            <img
              src={smallAnimationGif}
              alt={`How to use Clearword ${post.id}`}
              className="w-[90%]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Meet;
