
const baseUrl = 'https://clearword.com';
const useCasesBaseUrl = `${baseUrl}/use-cases/`;
const useCasesPages = {
    "csx": "customers",
    "agency": "agency",
    "leaders": "founders-leadership",
    "hiring": "hiring-and-interviews",
    "sales": "sales-and-marketing",
    "pm": "product-and-design"
};

const getUseCaseUrl = (cohort) => {
    if (!useCasesPages[cohort]) return baseUrl;
    return `${useCasesBaseUrl}${useCasesPages[cohort]}`;
};

const meetPersona = [
    {
        id: "tom",
        name: "Tom",
        intro: "Hi, I'm Tom 👋 I lead a marketing team",
        text: "Before Clearword, tracking brainstorm ideas was tough. Now, tasks update instantly, saving me hours weekly and keeping us organized.",
        learn: getUseCaseUrl('sales'),
    }, {
        id: "ryan",
        name: "Ryan",
        intro: "Hi, I'm Ryan 👋 and I manage sales accounts.",
        text: "Clearword gives me immediate insights during and after client calls, filling my CRM and enhancing follow-ups for stronger client relationships.",
        learn: getUseCaseUrl('sales'),
    },
    {
        id: "beth",
        name: "Beth",
        intro: "Hi, I'm Beth 👋 and I analyze customer feedback.",
        text: "I used to rely on delayed reports for customer insights. Now, with Clearword, I get immediate, direct feedback from calls, enabling quicker, more relevant product improvements.",
        learn: getUseCaseUrl('csx'),
    },
    {
        id: "jack",
        name: "Jack",
        intro: "Hi, I'm Jack 👋 and I work in customer service",
        text: "Clearword keeps track of customer conversations for me, updates my task tracker and CRM during the call, makes follow-ups quick, improves overall satisfaction and shows our customers we care.",
        learn: getUseCaseUrl('csx'),
    }, {
        id: "olivia",
        name: "Olivia",
        intro: "Hi, I'm Olivia 👋 a customer success manager.",
        text: "Clearword saves me hours by organizing call notes automatically, letting me focus on customer satisfaction and updating the CRM for me live during calls so I forget nothing our customers say.",
        learn: getUseCaseUrl('csx'),
    }, {
        id: "kevin",
        name: "Kevin",
        intro: "Hi, I'm Kevin 👋 and I manage real estate",
        text: "I struggled with meetings. Clearword sorts updates and client info during my calls and gives me a summary of every conversation with action items post-meeting, saving time to sell more houses.",
        learn: getUseCaseUrl('default'),
    }, {
        id: "maya",
        name: "Maya",
        intro: "Hi, I'm Maya 👋 and I work in marketing",
        text: "Before Clearword, analyzing customer calls took ages. Now, I get instant summaries and my task tracker is filled up during my calls, making my marketing strategies more targeted and effective.",
        learn: getUseCaseUrl('sales'),
    }, {
        id: "rachel",
        name: "Rachel",
        intro: "Hi, I'm Rachel 👋 and I handle e-commerce customer service",
        text: "Clearword sorts all our customer feedback and action plans during and post-meeting. I can make quick, effective changes to enhance our service and convert more customers.",
        learn: getUseCaseUrl('csx'),
    }, {
        id: "deion",
        name: "Deion",
        intro: "Hi, I'm Deion 👋 a partner at a venture fund",
        text: "Clearword saves me hours by auto-updating our CRM with key meeting points, making sharing insights with my team effortless and fast.",
        learn: getUseCaseUrl('default'),
    },{
        id: "leo",
        name: "Leo",
        intro: "Hi, I'm Leo 👋 a startup founder",
        text: "Clearword sorts my meeting notes and tasks instantly and exports them into Monday.com, letting me focus on growing my business instead of getting lost in paperwork and notes.",
        learn: getUseCaseUrl('leaders'),
    }, {
        id: "emily",
        name: "Emily",
        intro: "Hi, I'm Emily 👋 I'm the CEO of a scale-up",
        text: "Clearword instantly organizes my meeting points and tasks during my calls, freeing me to lead my company without ever forgetting anything my investors, customers, and team said.",
        learn: getUseCaseUrl('leaders'),
    }, {
        id: "jen",
        name: "Jen",
        intro: "Hi, I'm Jen 👋 and I'm a tech lead",
        text: "Clearword saves me hours by instantly updating our project tracker with meeting tasks and notes during the meeting. When the meeting's over, everything's already in Jira.",
        learn: getUseCaseUrl('leaders'),
    }, {
        id: "sam",
        name: "Sam",
        intro: "Hi, I'm Sam 👋 the HR lead at a tech firm.",
        text: "Before Clearword, I spent hours logging meeting notes. Now, they're in our system instantly before the meeting is even over, giving me more time to support my team.",
        learn: getUseCaseUrl('default'),
    }, {
        id: "mike",
        name: "Mike",
        intro: "Hi, I'm Mike 👋 and I'm the COO of a medium-sized company",
        text: "I used to lose hours organizing notes post-meetings, but with Clearword, everything's instantly in the system - tasks, notes, everything. It's like a magic time-saver for me and my team.",
        learn: getUseCaseUrl('leaders'),
    }, {
        id: "sara",
        name: "Sara",
        intro: "Hi, I'm Sara 👋 and I run a green energy startup",
        text: "Before Clearword, I was swamped with meetings. Now, I finish meetings with my notes organized in our task tracker and my team is always aligned. It saves me hours which I focus on the business.",
        learn: getUseCaseUrl('leaders'),
    }, {
        id: "john",
        name: "John",
        intro: "Hi, I'm John 👋 and I'm a PM",
        text: "I used to spend hours organizing post-meeting work. With Clearword, I leave meetings with everything actionable and organized, saving time for strategic tasks. It's like an extra team member turning discussions into immediate, shareable actions.",
        learn: getUseCaseUrl('pm'),
    }, {
        id: "alex",
        name: "Alex",
        intro: "Hi, I'm Alex 👋 I'm a freelance consultant",
        text: "I struggled to organize post-meeting tasks with all my clients, but Clearword instantly gives me a to-do list for each client, saving time and keeping clients satisfied. This means more revenue in the end.",
        learn: getUseCaseUrl('agency'),
    }, {
        id: "adam",
        name: "Adam",
        intro: "Hi, I'm Adam 👋, I'm a team lead",
        text: "I was swamped with resumes and interviews. Clearword lets me review interview insights quickly, without being there. It's a huge time-saver, allowing me to focus on my team.",
        learn: getUseCaseUrl('pm'),
    }, {
        id: "laura",
        name: "Laura",
        intro: "Hi, I'm Laura 👋 and I run a growing company",
        text: "I often struggle to recall every detail from my team's conversations. Clearword helps me keep track of everything, making sure I remember every key point, actions, decisions and also stay organized.",
        learn: getUseCaseUrl('leaders'),
    }

];

const findById = (id) => {
    return meetPersona.find(persona => persona.id === id);
};

export { meetPersona, findById };
