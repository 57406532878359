import {
  BrowserRouter as Router,
  useRoutes,
} from 'react-router-dom';

import Layout from './components/base/layout';
import Meets from './components/meets';
import Meet from './components/meet';

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Layout><Meets /></Layout> },
    { path: "/:id", element: <Layout><Meet /></Layout>},
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};


export default AppWrapper;
